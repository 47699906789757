'use strict';

!(function() {
  // console.log('first.js START UNIXTIME: ' + new Date().getTime());

  var lineFromLeftElm = document.getElementsByClassName('u-text-from-bottom');
  for (var i = 0; i < lineFromLeftElm.length; i++) {
    var lineFromLeftHTML = '';
    var lineFromLeftTextArray = lineFromLeftElm[i].textContent.split('');
    for (var j = 0; j < lineFromLeftTextArray.length; j++) {
      lineFromLeftHTML += '<span>' + lineFromLeftTextArray[j] + '</span>';
    }
    lineFromLeftElm[i].innerHTML = lineFromLeftHTML;
  }

  // console.log('first.js END UNIXTIME: ' + new Date().getTime());
})();
